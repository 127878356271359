@import url(https://fonts.googleapis.com/css?family=Lato:400,700,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Muli%3A300%2C300i%2C400%2C400i%2C600%2C600i%7COpen+Sans+Condensed%3A300&ver=1.6.0);
#geographySearchBox {
  background: black;
  color: white;
  border-radius: var(--border-radius);
  border: none;
  font-weight: 400;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 3.2rem;
  font-size: 1.5rem !important;
  text-rendering: geometricPrecision;
  text-overflow: ellipsis;
}

.search-screen #geographySearchBox {
  font-size: 1.8rem !important;
  color: black;
  background: white;
  height: 5rem;
  padding-left: 4.5rem;
}

.search-screen #geographySearchBox::-webkit-input-placeholder {
  color: var(--text-700);
}

.search-screen #geographySearchBox:-ms-input-placeholder {
  color: var(--text-700);
}

.search-screen #geographySearchBox::-ms-input-placeholder {
  color: var(--text-700);
}

.search-screen #geographySearchBox::placeholder {
  color: var(--text-700);
}

#geographySearchBox:focus {
  background: white;
  color: black;
  outline: none !important;
  box-shadow: var(--box-shadow-small);
}

#SearchBoxIcon {
  width: 1.6em;
  margin-left: 0.9em;
  z-index: 1;
}

.search-screen #SearchBoxIcon {
  width: 3rem;
}

#SearchBoxIcon path {
  fill: transparent;
  stroke: var(--text-500);
}

.search-screen #SearchBoxIcon path {
  stroke: var(--text-800);
}

#glossaryInput {
  padding-left: 2.5rem;
}

#glossarySearchBox {
  width: 120%;
}

#glossarySearchBoxIcon {
  opacity: 0.5;
  margin-bottom: -45px;
  width: 20px;
}

/* The hamburger menu icon is 24px tall with 12px padding, and the whole header has 2px padding.
   This sets the resulting 52px height as the minimum so it will match on the other pages,
   where the contents aren't as tall so it would otherwise be slightly thinner.*/
#app-header {
  min-height: 52px;
}

#wildfireLogo {
  height: 100%;
  padding-left: 10px;
}

.restrict-growth-content-container {
  max-width: 1100px !important;
  margin: 0 auto;
}

.set-width-content-container {
  width: 1100px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.GeoSearch-item {
  float: right;
  color: gray;
}

.GlossaryHeader-divider {
  width: 100%;
  margin: 2rem 0 0;
}

.risk-to-homes g > g > line {
  stroke: #333 !important;
  stroke-width: 2 !important;
}

.risk-to-homes g > g > g > line {
  stroke: #333 !important;
  stroke-width: 1 !important;
}

.search-screen-lower .appHeader {
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #000;
  margin: 30px 0;
}

.search-screen-lower .appHeader .appHeaderText h1 {
  font-size: 28px;
  margin: 0 0 30px;
}
.search-screen-lower .appHeader .appHeaderText p {
  font-size: 18px;
  margin: 0;
}

#map-container.mapboxgl-map {
  font-size: 1.4rem;
}

.mapboxgl-canvas-container {
  z-index: 1;
}

.mapboxgl-input {
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.mapboxgl-input-checkbox {
  margin: 0;
  cursor: pointer;
}

.mapboxgl-input-label {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 3px 5px 3px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.mapboxgl-input input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: relative;
  display: inline-block;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #fff;
  margin-right: 5px;
  border: 1px solid #ccc;
  border-radius: 2px;
}

.mapboxgl-input:hover input ~ .checkmark {
  background-color: #f4f2f0;
}

.mapboxgl-input input:checked ~ .checkmark {
  background-color: #3a312d;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.mapboxgl-input input:checked ~ .checkmark:after {
  display: block;
}

.mapboxgl-input .checkmark:after {
  left: 5px;
  top: 1px;
  width: 4px;
  height: 9px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.mapboxgl-canvas {
  outline: none;
}

.mapboxgl-ctrl-group:not(:empty) {
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow-small) !important;
  cursor: default;
}

.mapboxgl-ctrl > div {
  background-color: transparent;
}

.mapboxgl-ctrl-group.mapboxgl-input-autowidth button {
  width: auto;
}

/* Keep the mapbox logo from showing up on top of the vulnerable populations panel */
.mapboxgl-ctrl-bottom-left {
  z-index: 1 !important;
  padding: 5px !important;
}

.mapboxgl-ctrl-group button:focus {
  box-shadow: none !important;
}

button[role="option"]:focus-visible,
.mapboxgl-ctrl-group button:has(.mapboxgl-ctrl-icon):focus-visible {
  outline-offset: -4px !important;
}

.mapboxgl-ctrl-group button.glossary-link {
  display: inline-block;
}

.no-highlighted-tracts-popup {
  z-index: 1 !important;
}

.no-highlighted-tracts-popup .mapboxgl-popup-tip {
  display: none !important;
}

.vulnerable-populations-widget {
  position: absolute;
  z-index: 40;
}

.vulnerable-populations-tract-detail-panel {
  position: absolute;
  z-index: 45;
}

.compare-to input {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.compare-to button {
  border-color: #ddd;
  padding-right: 11px;
}

.header-footer-nav-links {
  -webkit-text-decoration-line: none !important;
          text-decoration-line: none !important;
  font-size: 1.4rem;
  text-wrap: nowrap;
}

.tract-popup {
  z-index: 50;
}

.tract-popup,
.tract-popup * {
  pointer-events: none !important;
}

.mapboxgl-popup-content {
  padding-bottom: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
  line-height: 15px !important;
  box-shadow: none !important;
}

.content-box {
  background-color: white;
  border-radius: 0.2rem;
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
}

.content-box.dark {
  background-color: var(--warm-gray-900);
}

.content-box h3 {
  padding: 1.25rem 0;
  border-bottom: 1px solid var(--warm-gray-400);
}

.content-box.dark h3 {
  color: white;
  border-color: rgba(255, 255, 255, 0.3);
}

.content-box p + p {
  margin-top: 0.5em;
}

.button-link {
  color: var(--highlight-800);
  background: var(--highlight-200);
  text-decoration: none;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.8rem !important;
  border-radius: var(--border-radius);
  grid-gap: 1em;
  gap: 1em;
}

.button-link.dark {
  background: transparent;
  border: 0.1rem solid rgba(255, 255, 255, 0.4);
  color: white;
}

.button-link span {
  display: inline-flex;
  align-items: center;
  grid-gap: 0.7em;
  gap: 0.7em;
}

@media (hover: hover) {
  .button-link:hover,
  .action-card.highlight:hover {
    background: var(--highlight-400) !important;
  }

  .button-link.dark:hover {
    background: rgba(255, 255, 255, 0.2) !important;
  }
}

.button-link.small {
  padding: 0.1rem 0.5rem;
  background: var(--highlight-100);
  font-size: 1.5rem !important;
}

.button-link.medium {
  padding: 0.9rem 1rem;
}

.button-link.large {
  padding: 1.15rem 1.5rem;
}

.action-card {
  background: white;
  border: 1px solid var(--warm-gray-400);
  border-radius: var(--border-radius);
  --icon-color: black;
}

.content-box.dark .action-card {
  border: none !important;
}

.action-card:hover {
  background: var(--warm-gray-200);
}

.action-card:active {
  opacity: 0.6;
}

.action-card.highlight h4 {
  text-decoration: underline;
  -webkit-text-decoration-color: var(--highlight-800);
          text-decoration-color: var(--highlight-800);
}

.content-box.dark .action-card:hover {
  background: var(--highlight-400);
}

.action-card.highlight,
.content-box.dark .action-card {
  background: var(--highlight-200);
  border: 1px solid var(--highlight-700);
  --icon-color: var(--highlight-700);
}

.action-card.highlight a,
.content-box.dark .action-card a {
  color: var(--highlight-800);
}

.action-card:has(h4 a:focus-visible),
.action-card:has(h5 a:focus-visible) {
  outline: 2px solid var(--highlight-700);
  outline-offset: 2px;
}

.action-card.highlight p,
.content-box.dark .action-card p {
  color: var(--text-800);
}

footer a:hover {
  opacity: 0.75;
}
#glossary-box ul {
  padding: 0;
  list-style: none;
}

#glossary-box li {
  border-bottom: 1px solid var(--warm-gray-300);
}

#glossar-box li:last-child {
  border: none;
}

#glossary-box li a {
  padding: 0.5rem 0.8rem;
  padding-left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--text-900);
  font-weight: bold;
}

#glossary-box li a:hover {
  background-color: var(--warm-gray-200);
}

.multi-toggle {
  display: inline-flex;
  flex-direction: row !important;
  flex-shrink: 0;
  border: 1px solid var(--warm-gray-400);
  border-radius: var(--border-radius);
  overflow: hidden;
  align-items: center;
  line-height: 1.9rem;
}

.multi-toggle label {
  background: var(--warm-gray-200);
  padding: 0.4rem 0.9rem 0.5rem 0.9rem;
  font-size: 1.5rem;
  color: var(--text-800);
}

.multi-toggle label:hover {
  background: var(--warm-gray-300);
}

.multi-toggle div:has(input[type="radio"]) {
  position: absolute;
  opacity: 0;
  width: 0;
}

.multi-toggle label:last-child {
  border: none !important;
}

.multi-toggle label:has(input[type="radio"]:checked) {
  color: black;
  font-weight: 700;
  background-color: white;
  cursor: default;
}

.multi-toggle label:has(input[type="radio"]:focus-visible) {
  outline: 2px solid var(--highlight-700);
  outline-offset: -4px;
  border-radius: 1px;
  z-index: 99;
}

.mapboxgl-ctrl-group .multi-toggle {
  border: none !important;
  font-family: var(--font-family);
  font-size: 1.4rem;
}

.multi-toggle label {
  border-radius: 0 !important;
  border-right: 1px solid var(--warm-gray-400) !important;
}

/* Grommet doesn't respect the justify prop to allow aligning button icons. Hence this hack*/
.button-space-between {
  align-items: center;
}
.button-space-between div {
  display: contents;
}

#breadcrumb-nav ol {
  list-style: none;
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  padding: 0;
  margin: 0;
}

#breadcrumb-nav li {
  display: inline-flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

#breadcrumb-nav li::after {
  height: 20px;
  width: 0.7rem;
  display: inline-flex;
  content: "";
  position: relative;
  background: url('data:image/svg+xml,<svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.05469 4.82031C5.30859 5.05469 5.30859 5.46484 5.05469 5.69922L1.30469 9.44922C1.07031 9.70312 0.660156 9.70312 0.425781 9.44922C0.171875 9.21484 0.171875 8.80469 0.425781 8.57031L3.72656 5.25L0.425781 1.94922C0.171875 1.71484 0.171875 1.30469 0.425781 1.07031C0.660156 0.816406 1.07031 0.816406 1.30469 1.07031L5.05469 4.82031Z" fill="%23BCB5B0"/></svg>')
    center center no-repeat;
}

table caption {
  text-align: left;
  font-size: 1.5rem;
  color: var(--text-800);
  padding-bottom: 0.5rem;
}

/* Elements with this class are invisible but will be read by screen readers */
.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

#main-content {
  --bar-height: 5.2rem;
  scroll-margin-top: var(--scroll-offset);
}

.mapboxgl-ctrl-bottom-right {
  display: grid;
  grid-template-columns: auto auto;
  align-items: end;
  justify-items: end;
}

.mapboxgl-ctrl-bottom-right .mapboxgl-ctrl-group {
  grid-column: 2;
}

.mapboxgl-ctrl-attrib {
  border-top-left-radius: var(--border-radius);
  font-family: var(--font-family);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  border-radius: var(--border-radius);
  margin: 1rem !important;
  margin-top: 0 !important;
  background-color: #ffffff35 !important;
}

.mapboxgl-ctrl-scale {
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  background-color: #ffffff50 !important;
  opacity: 1 !important;
  display: inline;
  position: relative !important;
  border: none !important;
  border-bottom: 2px solid black !important;
  padding: 0 !important;
  color: black !important;
  font-size: 12px !important;
  line-height: 1em !important;
  padding: 0.3em 0 !important;
  height: auto !important;
  font-family: var(--font-family);
}

:root {
  --warm-gray-900: #39312d;
  --warm-gray-700: #7c675a;
  --warm-gray-600: #a69183;
  --warm-gray-500: #bcb5b0;
  --warm-gray-400: #d8d3cf;
  --warm-gray-300: #e9e5e2;
  --warm-gray-200: #f3f2f0;
  --warm-gray-100: #fafafa;
  --highlight-800: #00698f;
  --highlight-700: #0095cb;
  --highlight-400: #cee8f5;
  --highlight-200: #e5f8ff;
  --highlight-100: #f2f7f9;
  --text-900: #333333;
  --text-800: #555555;
  --text-700: #757575;
  --text-500: #cccccc;
  --teal-900: #00686f;
  --teal-700: #02929b;
  --teal-400: #cce9eb;

  --border-radius: 2px;
  /* Generated using https://www.joshwcomeau.com/shadow-palette/ */
  --shadow-color: 0deg 0% 0%;
  --box-shadow-small: 0px 1.6px 1.8px -1.7px hsl(var(--shadow-color) / 0.1),
    0px 2px 4.5px -2.5px hsl(var(--shadow-color) / 0.2), 0 0 0px 1px hsl(var(--shadow-color) / 0.1);
  --box-shadow-medium: 0px 1.1px 1.4px -0.4px hsl(var(--shadow-color) / 0.08),
    0px 2.3px 3px -0.9px hsl(var(--shadow-color) / 0.1),
    -0.1px 5.2px 6.8px -1.3px hsl(var(--shadow-color) / 0.13),
    0 0 0px 1px hsl(var(--shadow-color) / 0.1);
  --box-shadow-large: 0px 0.7px 0.9px hsl(var(--shadow-color) / 0.06),
    -0.1px 5.7px 7.2px -0.5px hsl(var(--shadow-color) / 0.08),
    -0.2px 14.2px 18px -1px hsl(var(--shadow-color) / 0.1),
    -0.5px 33.6px 42.6px -1.5px hsl(var(--shadow-color) / 0.12);

  --font-family: "Lato", sans-serif;
  --font-family-menu: "Muli", sans-serif;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth !important;
}

@media (prefers-reduced-motion) {
  html {
    scroll-behavior: auto !important;
  }
}

body {
  margin: 0;
  font-family: "Lato", sans-serif;
  font-family: var(--font-family);
  text-rendering: geometricPrecision;
  background-color: #fafafa;
  background-color: var(--warm-gray-100);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
  color: inherit;
}

hr {
  height: 1px;
  background: #e9e5e2;
  background: var(--warm-gray-300);
  width: 100%;
  border: 0;
  margin: 0;
}

:focus-visible {
  outline: 2px solid #0095cb !important;
  outline: 2px solid var(--highlight-700) !important;
  outline-offset: 2px !important;
  border-radius: 1px;
  z-index: 99;
}

:focus:not(:focus-visible) {
  outline: none;
}

a:active,
button:active,
summary:active {
  opacity: 0.6 !important;
}

p,
figure {
  margin: 0;
}

p a {
  text-decoration: underline;
  text-underline-offset: 0.1em;
  text-decoration-thickness: 1px;
  -webkit-text-decoration-color: #bcb5b0;
          text-decoration-color: #bcb5b0;
  -webkit-text-decoration-color: var(--warm-gray-500);
          text-decoration-color: var(--warm-gray-500);
}

p a:hover {
  color: black;
  -webkit-text-decoration-color: #757575;
          text-decoration-color: #757575;
  -webkit-text-decoration-color: var(--text-700);
          text-decoration-color: var(--text-700);
}

ul {
  margin: 0;
  padding: 0;
}

button {
  text-rendering: geometricPrecision;
}


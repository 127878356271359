@import url("https://fonts.googleapis.com/css?family=Lato:400,700,900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Muli%3A300%2C300i%2C400%2C400i%2C600%2C600i%7COpen+Sans+Condensed%3A300&ver=1.6.0");

:root {
  --warm-gray-900: #39312d;
  --warm-gray-700: #7c675a;
  --warm-gray-600: #a69183;
  --warm-gray-500: #bcb5b0;
  --warm-gray-400: #d8d3cf;
  --warm-gray-300: #e9e5e2;
  --warm-gray-200: #f3f2f0;
  --warm-gray-100: #fafafa;
  --highlight-800: #00698f;
  --highlight-700: #0095cb;
  --highlight-400: #cee8f5;
  --highlight-200: #e5f8ff;
  --highlight-100: #f2f7f9;
  --text-900: #333333;
  --text-800: #555555;
  --text-700: #757575;
  --text-500: #cccccc;
  --teal-900: #00686f;
  --teal-700: #02929b;
  --teal-400: #cce9eb;

  --border-radius: 2px;
  /* Generated using https://www.joshwcomeau.com/shadow-palette/ */
  --shadow-color: 0deg 0% 0%;
  --box-shadow-small: 0px 1.6px 1.8px -1.7px hsl(var(--shadow-color) / 0.1),
    0px 2px 4.5px -2.5px hsl(var(--shadow-color) / 0.2), 0 0 0px 1px hsl(var(--shadow-color) / 0.1);
  --box-shadow-medium: 0px 1.1px 1.4px -0.4px hsl(var(--shadow-color) / 0.08),
    0px 2.3px 3px -0.9px hsl(var(--shadow-color) / 0.1),
    -0.1px 5.2px 6.8px -1.3px hsl(var(--shadow-color) / 0.13),
    0 0 0px 1px hsl(var(--shadow-color) / 0.1);
  --box-shadow-large: 0px 0.7px 0.9px hsl(var(--shadow-color) / 0.06),
    -0.1px 5.7px 7.2px -0.5px hsl(var(--shadow-color) / 0.08),
    -0.2px 14.2px 18px -1px hsl(var(--shadow-color) / 0.1),
    -0.5px 33.6px 42.6px -1.5px hsl(var(--shadow-color) / 0.12);

  --font-family: "Lato", sans-serif;
  --font-family-menu: "Muli", sans-serif;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth !important;
}

@media (prefers-reduced-motion) {
  html {
    scroll-behavior: auto !important;
  }
}

body {
  margin: 0;
  font-family: var(--font-family);
  text-rendering: geometricPrecision;
  background-color: var(--warm-gray-100);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
  color: inherit;
}

hr {
  height: 1px;
  background: var(--warm-gray-300);
  width: 100%;
  border: 0;
  margin: 0;
}

:focus-visible {
  outline: 2px solid var(--highlight-700) !important;
  outline-offset: 2px !important;
  border-radius: 1px;
  z-index: 99;
}

:focus:not(:focus-visible) {
  outline: none;
}

a:active,
button:active,
summary:active {
  opacity: 0.6 !important;
}

p,
figure {
  margin: 0;
}

p a {
  text-decoration: underline;
  text-underline-offset: 0.1em;
  text-decoration-thickness: 1px;
  text-decoration-color: var(--warm-gray-500);
}

p a:hover {
  color: black;
  text-decoration-color: var(--text-700);
}

ul {
  margin: 0;
  padding: 0;
}

button {
  text-rendering: geometricPrecision;
}
